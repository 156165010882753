import {useState} from "react";
import logo from './logo.svg';
import './App.css';

function App() {

  const [form, setForm] = useState({
    amount: '',
    order_id: '',
    description: ''
  });

  const [customerForm, setCustomerForm] = useState({
    external_id: '',
    name: '',
    last_name: '',
    email: ''
  });

  const [barcodeImg, setBarcodeImg] = useState("");  
  const [referencia, setReferencia] = useState("");
  const [opResponse, setOPResponse] = useState(false);

  const changeForm = (event, setFunc) => {
    const {name, value} = event.target;
    setFunc(prev => ({...prev, [name]: value}));
  };

  const sendForm = () => {
    for(let i in form){
      if(form[i] == '') return alert("Debes llenar todos los campos del cargo")
    }
    for(let j in customerForm){
      if(customerForm[j] == '') return alert("Debes llenar todos los campos del cliente")
    }
    fetch('http://74.208.253.45:4000/', {
        method: 'POST',
        body: JSON.stringify({
            ...form, ...customerForm
        }),
        headers:{ 'Content-Type': 'application/json' },
    }).then(res=>res.json())
    .then(res => {
        console.log(res)
        setOPResponse(res);
        setBarcodeImg(res.payment_method.barcode_url);
        setReferencia(res.payment_method.reference);
    }).catch(err => {
        alert("Error creando cargo")
    });
  }

  return (
    <div className="App">
      <h1>Cargo</h1>
      <label>Monto</label>
      <input type="number" name="amount" value={form.amount} onChange={(e) => (changeForm(e, setForm))} />
      <label>Descripcion</label>
      <input type="text" name="description" value={form.description} onChange={(e) => (changeForm(e, setForm))} />
      <label># de orden</label>
      <input type="text" name="order_id" value={form.order_id} onChange={(e) => (changeForm(e, setForm))} />
      <h1>Cliente</h1>
      <label>ID externo</label>
      <input type="text" name="external_id" value={customerForm.external_id} onChange={(e) => (changeForm(e, setCustomerForm))} />
      <label>Nombre</label>
      <input type="text" name="name" value={customerForm.name} onChange={(e) => (changeForm(e, setCustomerForm))} />
      <label>Apellido</label>
      <input type="text" name="last_name" value={customerForm.last_name} onChange={(e) => (changeForm(e, setCustomerForm))} />
      <label>Email</label>
      <input type="email" name="email" value={customerForm.email} onChange={(e) => (changeForm(e, setCustomerForm))} />
      <button onClick={sendForm}>Generar</button>
      {
      opResponse && 
        <div className="OPResponse">
          <h3>Barcode</h3>
          <img src={barcodeImg} />
          <br></br>
          {referencia}
          <h3>Response</h3>
          <div className="responseCode">
            {
              JSON.stringify(opResponse)
            }
          </div>
        </div>
      }
    </div>
  );
}

export default App;
